import { createReducer } from "../../utils/redux/create-reducer";
import Types from "./types";
import saveCookie from "../../utils/helpers/save-cookie";
import deleteCookie from "../../utils/helpers/delete-cookie";

export const INITIAL_STATE = {
    mobile: "",
    lastVerifiedMobile: "",
    profileMobile: "",
    name: "",
    profileName: "",
    email: "",
    isLoggedIn: false,
    error: null,
    unAuthorized: true,
    isFetched: false,
    checkAuth: false,
    isFirstLoad: true,
    leadType: "",
    socketId: null,
    isSSR: false,
    trueCallerPromptCount: 0,
    isWebview: false,
    gaId: null,
    userId: "",
    currentPageType: null,
    secureToken: "",
    country: null,
    countryError: null,
    addresses: [],
    userAddressError: null,
    userDetailsSubmitted: null,
    bankingSubmitted: null,
    preApproved: null,
    zeroDPEligible: null,
    employmentType: null,
    showOfferPageFeedbackABTest: null,
    tofBankingAllowedABTest: null,
    diyCohort: null,
    diyVariant: null,
    defaultUploadBankingABTest: null,
    bankStatementInstructionsABTest: null,
    haptikControlVariant: null,
    trackLoanABTest: null,
    aqbABTest: null,
    offerEngineABTest: null,
    deliveryVerticalVariant: null,
    loginFlow: null,
    buyerAbTest: {
        isReservedCarNotifyVariant: true,
        isPriceAndModelDeals: false,
        isCdpRevampVariant: false,
        isCarExchangeApplicable: false,
        isTwoColumnCarCardVariant: false,
        isSimilarCarsWrtCarViewed: true,
        isROIOfferApplicable: true,
        isServiceHistoryOnAppVariant: true,
        showRecommendedCarsOnListing: false,
        isRecommendedForUVariant: false
    },
    buyerABTestSSR: false,
    bookingCount: {
        totalOrders: 0,
        count: {}
    },
    isBookingLimitExceeded: false,
    totalBooking: {},
    preApprovalScreenVariant: null,
    pincodeServiceable: true,
    bajajOfferStatus: null,
    bajajOfferSnackbar: null,
    cfConfig: {},
    loanCalculatorConfig: {},
    isUserSessionValid: false,
    addMoreCars: null,
    cibilConsent: "",
    statsigId: null,
    gaIdError: null,
    cibilStatus: null
};

export const setMobile = (state = INITIAL_STATE, { mobile }) => {
    return { ...state, mobile };
};

export const setLastVerifiedMobile = (state = INITIAL_STATE, { lastVerifiedMobile }) => {
    return { ...state, lastVerifiedMobile };
};

export const setProfileMobile = (state = INITIAL_STATE, { profileMobile }) => {
    return { ...state, profileMobile };
};

export const setName = (state = INITIAL_STATE, { name }) => {
    return { ...state, name };
};

export const setProfileName = (state = INITIAL_STATE, { profileName }) => {
    return { ...state, profileName };
};

export const setEmail = (state = INITIAL_STATE, { email }) => {
    return { ...state, email };
};

export const getProfileDetailsSuccess = (state = INITIAL_STATE,
    { name = "", mobile = "", email = "", userId = "", token = "", cibilConsent }
) => {
    // saveCookie("secureToken", token);
    saveCookie("userId", userId);
    saveCookie("userToken", token);
    return {
        ...state,
        name,
        profileName: name,
        mobile: mobile.toString(),
        profileMobile: mobile.toString(),
        email,
        isLoggedIn: true,
        unAuthorized: false,
        isFetched: true,
        userId,
        secureToken: token,
        cibilConsent
    };
};

export const getProfileDetailsFailure = (state = INITIAL_STATE, { error }) => {
    // deleteCookie("secureToken");
    return { ...state, error, isLoggedIn: false, isFetched: true, secureToken: "" };
};

export const markUnauthorized = (state = INITIAL_STATE) => {
    // deleteCookie("secureToken");
    deleteCookie("userId");
    deleteCookie("userToken");
    return {
        ...state,
        isLoggedIn: false,
        isFetched: true,
        secureToken: "",
        name: "",
        mobile: "",
        profileName: "",
        profileMobile: "",
        userId: "",
        userDetailsSubmitted: null,
        bankingSubmitted: null,
        preApproved: null,
        zeroDPEligible: null,
        employmentType: null,
        pincodeServiceable: true,
        cibilStatus: null
    };
};

export const setCheckAuth = (state = INITIAL_STATE, { checkAuth }) => {
    return { ...state, checkAuth };
};

export const setFirstLoad = (state = INITIAL_STATE) => {
    return { ...state, isFirstLoad: false };
};

export const updateLeadType = (state = INITIAL_STATE, { leadType }) => {
    return { ...state, leadType };
};

export const setSocketId = (state = INITIAL_STATE, { socketId }) => {
    return { ...state, socketId };
};

export const setTruecallerPromptCount = (state = INITIAL_STATE, { trueCallerPromptCount }) => {
    return { ...state, trueCallerPromptCount };
};

export const updateWebviewStatus = (state = INITIAL_STATE, { isWebview }) => {
    return { ...state, isWebview };
};

export const setGAId = (state = INITIAL_STATE, { gaId }) => {
    return { ...state, gaId, gaIdError: !gaId };
};

export const updateCurrentPageType = (state = INITIAL_STATE, { currentPageType }) => {
    return { ...state, currentPageType };
};

export const fetchUserCountrySuccess = (state = INITIAL_STATE, { data: { country } }) => {
    return { ...state, country };
};

export const fetchUserCountryFailure = (state = INITIAL_STATE, { countryError }) => {
    return { ...state, countryError };
};

export const getUserAddressesSuccess = (state = INITIAL_STATE, { data }) => {
    return { ...state, addresses: data.addressList, userAddressError: null };
};

export const getUserAddressesFailure = (state = INITIAL_STATE, { error }) => {
    return { ...state, userAddressError: error };
};

const getBookingCount = (state = INITIAL_STATE, { bookingCount }) => {
    return {
        ...state,
        bookingCount
    };
};

export const setLoanApplicationStatus = (state = INITIAL_STATE, {
    userDetailsSubmitted,
    bankingSubmitted,
    zeroDPEligible,
    bankingSkippable
}) => {
    return { ...state, userDetailsSubmitted, bankingSubmitted, zeroDPEligible, bankingSkippable };
};

export const setPreApproved = (state = INITIAL_STATE, {
    preApproved
}) => {
    return { ...state, preApproved };
};

export const setZeroDPEligible = (state = INITIAL_STATE, {
    zeroDPEligible
}) => {
    return { ...state, zeroDPEligible };
};

export const setABTestFlags = (state = INITIAL_STATE, {
    showOfferPageFeedbackABTest,
    tofBankingAllowedABTest
}) => {
    return {
        ...state,
        showOfferPageFeedbackABTest,
        tofBankingAllowedABTest
    };
};

export const setEmploymentType = (state = INITIAL_STATE, { employmentType }) => {
    return { ...state, employmentType };
};

export const disableFinanceFeedback = (state = INITIAL_STATE) => {
    return { ...state, showOfferPageFeedbackABTest: false };
};

export const setHaptikControlVarient = (state = INITIAL_STATE, {
    haptikControlVariant
}) => {
    return { ...state, haptikControlVariant };
};

export const getVariantsFromB2COMS = (state = INITIAL_STATE, {
    haptikControlVariant,
    deliveryVerticalVariant,
    loginFlow,
    equifax,
    equifaxLoader,
    cibilSoftPull,
    cfFeedback,
    addMoreCars
}) => {
    return {
        ...state,
        haptikControlVariant,
        deliveryVerticalVariant,
        loginFlow,
        equifax,
        equifaxLoader,
        cibilSoftPull,
        cfFeedback,
        addMoreCars
    };
};

export const setBuyerAbTest = (state = INITIAL_STATE, { buyerAbTest }) => {
    return { ...state, buyerAbTest: (buyerAbTest || {}) };
};

export const updateBuyerABTestSSR = (state = INITIAL_STATE, { buyerABTestSSR }) => {
    return { ...state, buyerABTestSSR };
};

const setBookingLimitExceeded = (state = INITIAL_STATE, { isBookingLimitExceeded }) => {
    return {
        ...state,
        isBookingLimitExceeded
    };
};

export const setPincodeServiceable = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        pincodeServiceable: data
    };
};

export const setCfExperimentVariants = (state = INITIAL_STATE, {
    diyCohort,
    diyVariant,
    defaultUploadBankingABTest,
    bankStatementInstructionsABTest,
    trackLoanABTest,
    aqbABTest,
    preApprovalScreenVariant,
    offerEngineABTest,
    panValidationVariant,
    aaThemeRevampVariant
}) => {

    return {
        ...state,
        diyCohort,
        diyVariant,
        defaultUploadBankingABTest,
        bankStatementInstructionsABTest,
        trackLoanABTest,
        aqbABTest,
        preApprovalScreenVariant,
        offerEngineABTest,
        panValidationVariant,
        aaThemeRevampVariant
    };

};

export const setBajajOfferStatus = (state = INITIAL_STATE, { bajajOfferStatus }) => {
    return {
        ...state, bajajOfferStatus
    };
};

export const setCfUserData = (state = INITIAL_STATE, { data }) => {
    const {
        preApproved,
        userDetailsSubmitted,
        bankingSubmitted,
        pincodeServiceable,
        zeroDPEligible,
        bajajOfferStatus,
        cfUserData
    } = data || {};
    const { inComeDetails, bajajOfferSnackbar } = cfUserData || {};
    const { employmentType } = inComeDetails || {};
    return {
        ...state,
        preApproved,
        userDetailsSubmitted,
        bankingSubmitted,
        pincodeServiceable,
        zeroDPEligible,
        bajajOfferStatus,
        employmentType,
        bajajOfferSnackbar
    };
};

export const setCFSnackBarStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        bajajOfferSnackbar: data
    };
};

export const setCfConfig = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        cfConfig: {
            ...data
        }
    };
};

export const setLoanCalculatorConfig = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        loanCalculatorConfig: {
            ...data
        }
    };
};

export const setUserCibilStatus = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        ...data
    };
};

export const HANDLERS = {
    [Types.SET_NAME]: setName,
    [Types.SET_PROFILE_NAME]: setProfileName,
    [Types.SET_MOBILE]: setMobile,
    [Types.SET_LAST_VERIFIED_MOBILE]: setLastVerifiedMobile,
    [Types.SET_PROFILE_MOBILE]: setProfileMobile,
    [Types.SET_EMAIL]: setEmail,
    [Types.GET_PROFILE_DETAILS_SUCCESS]: getProfileDetailsSuccess,
    [Types.MARK_UNAUTHORIZED]: markUnauthorized,
    [Types.CHECK_AUTH]: setCheckAuth,
    [Types.SET_FIRST_LOAD]: setFirstLoad,
    [Types.UPDATE_LEAD_TYPE]: updateLeadType,
    [Types.SET_SOCKET_ID]: setSocketId,
    [Types.TRUECALLER_PROMPT_COUNT]: setTruecallerPromptCount,
    [Types.UPDATE_WEBVIEW_STATUS]: updateWebviewStatus,
    [Types.SET_GA_ID]: setGAId,
    [Types.UPDATE_CURRENT_PAGE_TYPE]: updateCurrentPageType,
    [Types.UPDATE_PROFILE_SUCCESS]: getProfileDetailsSuccess,
    [Types.FETCH_USER_COUNTRY_SUCCESS]: fetchUserCountrySuccess,
    [Types.FETCH_USER_COUNTRY_FAILURE]: fetchUserCountryFailure,
    [Types.GET_USER_ADDRESSES_SUCCESS]: getUserAddressesSuccess,
    [Types.GET_USER_ADDRESSES_FAILURE]: getUserAddressesFailure,
    [Types.GET_BOOKING_COUNT]: getBookingCount,
    [Types.SET_LOAN_APPLICATION_STATUS]: setLoanApplicationStatus,
    [Types.SET_PRE_APPROVED]: setPreApproved,
    [Types.SET_ZERO_DP_ELIGIBLE]: setZeroDPEligible,
    [Types.SET_AB_TEST_FLAGS]: setABTestFlags,
    [Types.SET_EMPLOYMENT_TYPE]: setEmploymentType,
    [Types.DISABLE_FINANCE_FEEDBACK]: disableFinanceFeedback,
    [Types.GET_VARIANTS_FROM_B2C_OMS]: getVariantsFromB2COMS,
    [Types.SET_BUYER_AB_TEST]: setBuyerAbTest,
    [Types.UPDATE_BUYER_AB_TEST_SSR]: updateBuyerABTestSSR,
    [Types.BOOKING_LIMIT_EXCEEDED]: setBookingLimitExceeded,
    [Types.GET_PROFILE_DETAILS_FAILURE]: getProfileDetailsFailure,
    [Types.SET_PINCODE_SERVICEABLE]: setPincodeServiceable,
    [Types.SET_CF_EXPERIMENT_VARIANTS]: setCfExperimentVariants,
    [Types.SET_BAJAJ_OFFER_STATUS]: setBajajOfferStatus,
    [Types.SET_CF_USER_DATA]: setCfUserData,
    [Types.SET_CF_SNACKBAR_STATUS]: setCFSnackBarStatus,
    [Types.SET_CF_CONFIG]: setCfConfig,
    [Types.SET_CALCULATOR_CONFIG]: setLoanCalculatorConfig,
    [Types.SET_USER_CIBIL_STATUS]: setUserCibilStatus
};

export default createReducer(INITIAL_STATE, HANDLERS);
