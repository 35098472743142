export default () => {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;
    if (/android/i.test(userAgent)) {
        return "Android";
    }
    if (/iPad|iPhone|iPod|Macintosh/.test(userAgent) && !window.MSStream) {
        return "iOS";
    }
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }
    return "unknown";
};
