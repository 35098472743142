import { createReducer } from "../../../../utils/redux/create-reducer";
import Types from "./types";

export const INITIAL_STATE = {
    isLoading: true,
    error: null,
    intentData: {
        intentCaptured: false
    }
};

export const setIsDataLoading = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isLoading: data
    };
};

export const setIntentDataInit = (state = INITIAL_STATE) => {
    return {
        ...state,
        isLoading: true
    };
};

export const setIntentDataFailure = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isLoading: false,
        error: data
    };
};

export const setIntentDataInitSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        isLoading: false,
        intentData: data
    };
};

export const HANDLERS = {
    [Types.IS_DATA_LOADING]: setIsDataLoading,
    [Types.SET_INTENT_DATA_INIT]: setIntentDataInit,
    [Types.SET_INTENT_DATA_FAILURE]: setIntentDataFailure,
    [Types.SET_INTENT_DATA_SUCCESS]: setIntentDataInitSuccess
};

export default createReducer(INITIAL_STATE, HANDLERS);
