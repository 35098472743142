import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { withRouter } from "react-router-dom";
import { renderRoutes } from "react-router-config";
import PageLoader from "./components/shared/page-loader";
import withDesktopConfiguration from "./pages/desktop/with-desktop-configuration";

const Seo = loadable(() => import("./pages/desktop/seo"), { fallback: <PageLoader />, ssr: true });
const Home = loadable(() => import("./pages/desktop/home"), { fallback: <PageLoader />, ssr: true });
const AppLanding = loadable(() => import("./pages/desktop/app-landing"), { fallback: <PageLoader />, ssr: true });
const TermsOfUse = loadable(() => import("./pages/desktop/terms-of-use"), { fallback: <PageLoader />, ssr: true });

const CarEvaluation = loadable(() => import("./pages/desktop/car-evaluation"), { fallback: <PageLoader />, ssr: true });
const Appointment = loadable(() => import("./pages/desktop/appointment"), { fallback: <PageLoader />, ssr: true });
const Experience = loadable(() => import("./pages/desktop/experience"), { fallback: <PageLoader />, ssr: true });
const ReviewSubmission = loadable(() => import("./pages/desktop/review-submission"), { fallback: <PageLoader />, ssr: true });
const UsedCarValuation = loadable(() => import("./pages/desktop/used-car-valuation"), { fallback: <PageLoader />, ssr: true });
const NeedHelp = loadable(() => import("./pages/desktop/need-help"), { fallback: <PageLoader />, ssr: true });
const Cars24Pricing = loadable(() => import("./pages/desktop/cars24-pricing"), { fallback: <PageLoader />, ssr: true });
const Unsubscribe = loadable(() => import("./pages/desktop/unsubscribe"), { fallback: <PageLoader />, ssr: true });
const Franchise = loadable(() => import("./pages/desktop/franchise"), { fallback: <PageLoader />, ssr: true });

const Confirmation = loadable(() => import("./pages/desktop/confirmation"), { fallback: <PageLoader />, ssr: true });
const NotFound = loadable(() => import("./pages/desktop/not-found"), { fallback: <PageLoader />, ssr: true });
const RTO = loadable(() => import("./pages/desktop/rto"), { fallback: <PageLoader />, ssr: true });
const ClassifiedCars24 = loadable(() => import("./pages/desktop/classified-vs-cars24"), { fallback: <PageLoader />, ssr: true });
const Documents = loadable(() => import("./pages/desktop/documents"), { fallback: <PageLoader />, ssr: true });
const BecomeOurPartner = loadable(() => import("./pages/desktop/become-our-partner"), { fallback: <PageLoader />, ssr: true });
const Refer = loadable(() => import("./pages/desktop/refer"), { fallback: <PageLoader />, ssr: true });
const Cars24Protect = loadable(() => import("./pages/desktop/cars24-protect"), { fallback: <PageLoader />, ssr: true });
const AllianceOffer = loadable(() => import("./pages/desktop/alliance-offer"), { fallback: <PageLoader />, ssr: true });
const OrderPayment = loadable(() => import("./pages/desktop/order-payment"), { fallback: <PageLoader />, ssr: true });
const BuyUsedCarFeedback = loadable(() => import("./pages/desktop/buy-used-cars-feedback"), { fallback: <PageLoader />, ssr: true });
const TokenTermsCondition = loadable(() => import("./pages/desktop/token-terms-condition"), { fallback: <PageLoader />, ssr: true });
const AppDownload = loadable(() => import("./pages/desktop/app-download"), { fallback: <PageLoader />, ssr: true });
const Payment = loadable(() => import("./pages/desktop/payment"), { fallback: <PageLoader />, ssr: true });
const EPayment = loadable(() => import("./pages/desktop/e-payment"), { fallback: <PageLoader />, ssr: true });
const SellOnlineCarEvaluation = loadable(() => import("./pages/desktop/sell-online-car-evaluation/component"), { fallback: <PageLoader />, ssr: true });
const evaluation = loadable(() => import("./pages/desktop/sell-online-car-evaluation/sell-online-evaluation"), { fallback: <PageLoader />, ssr: true });
const SellOnlineContainer = loadable(() => import("./pages/desktop/sell-online-home"), { fallback: <PageLoader />, ssr: true });
const BankStatement = loadable(() => import("./pages/desktop/bank-statement"), { fallback: <PageLoader />, ssr: true });
const DSA = loadable(() => import("./pages/desktop/dsa"), { fallback: <PageLoader />, ssr: true });
const DsaStepContainer = loadable(() => import("./pages/desktop/dsa-evaluation"), { fallback: <PageLoader />, ssr: true });
const SellUsedCar = loadable(() => import("./pages/desktop/sell-used-car"), { fallback: <PageLoader />, ssr: true });
const UserTermsAndConditions = loadable(() => import("./components/desktop/user-consent-terms"), { fallback: <PageLoader />, ssr: true });
const PostInspection = loadable(() => import("./pages/desktop/post-inspection"), { fallback: <PageLoader />, ssr: true });
const SellMarketing = loadable(() => import("./pages/desktop/sell-marketing"), { fallback: <PageLoader />, ssr: true });
const TermsAndCondition = loadable(() => import("./pages/desktop/terms-conditions-page"), { fallback: <PageLoader />, ssr: true });
const Nda = loadable(() => import("./pages/desktop/nda-page"), { fallback: <PageLoader />, ssr: true });
const HallOfFame = loadable(() => import("./pages/desktop/hall-of-fame"), { fallback: <PageLoader />, ssr: true });
const whyCars24 = loadable(() => import("./pages/desktop/why-cars24"), { fallback: <PageLoader />, ssr: true });
const TuCibilTerms = loadable(() => import("./pages/desktop/tu-cibil-terms"), { fallback: <PageLoader />, ssr: true });
const EChallan = loadable(() => import("./pages/desktop/e-challan"), { fallback: <PageLoader />, ssr: true });
const StateEChallan = loadable(() => import("./pages/desktop/state-e-challan"), { fallback: <PageLoader />, ssr: true });
const MyChallans = loadable(() => import("./pages/desktop/my-challans"), { fallback: <PageLoader />, ssr: true });
const ScrapCars = loadable(() => import("./pages/desktop/scrap-cars"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsInfo = loadable(() => import("./pages/desktop/scrap-cars-info"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsQuestions = loadable(() => import("./pages/desktop/scrap-cars-questions"), { fallback: <PageLoader />, ssr: true });
const ScrapCarsFinalPrice = loadable(() => import("./pages/desktop/scrap-cars-final-price"), { fallback: <PageLoader />, ssr: true });
const EmployeeFnF = loadable(() => import("./pages/desktop/employee-fnf"), { fallback: <PageLoader />, ssr: true });
const MyOrderSummary = loadable(() => import("./pages/desktop/my-order-summary"), { fallback: <PageLoader />, ssr: true });
const MyOrderStatus = loadable(() => import("./pages/desktop/my-order-status"), { fallback: <PageLoader />, ssr: true });
const MyOrders = loadable(() => import("./pages/desktop/my-orders"), { fallback: <PageLoader />, ssr: true });
const Fourdoor = loadable(() => import("./pages/desktop/fourdoor"), { fallback: <PageLoader />, ssr: true });
const RtoDetails = loadable(() => import("./pages/desktop/rto-details"), { fallback: <PageLoader />, ssr: false });
const MyProfile = loadable(() => import("./pages/desktop/my-profile"), { fallback: <PageLoader />, ssr: false });
const AccountLogin = loadable(() => import("./pages/desktop/account-login"), { fallback: <PageLoader />, ssr: true });
const UserAppointments = loadable(() => import("./pages/desktop/user-appointments"), { fallback: <PageLoader />, ssr: false });
const CarServiceHistory = loadable(() => import("./pages/desktop/car-service-history"), { fallback: <PageLoader />, ssr: true });
const CarServiceHistoryInfo = loadable(() => import("./pages/desktop/car-service-history-info"), { fallback: <PageLoader />, ssr: true });
const PreDeliveryInspection = loadable(() => import("./pages/desktop/pre-delivery-inspection"), { fallback: <PageLoader />, ssr: true });
const FranchiseForm = loadable(() => import("./pages/desktop/franchise-form"), { fallback: <PageLoader />, ssr: false });
const CarHealth = loadable(() => import("./pages/desktop/car-health"), { fallback: <PageLoader />, ssr: false });
const PreDeliveryInspectionAppointment = loadable(() => import("./pages/desktop/pre-delivery-inspection-appointment"), { fallback: <PageLoader />, ssr: false });
const PreDeliveryInspectionConfirmation = loadable(() => import("./pages/desktop/pre-delivery-inspection-confirmation"), { fallback: <PageLoader />, ssr: false });
const SellerVasReportDownload = loadable(() => import("./pages/desktop/seller-vas-report-download"), { fallback: <PageLoader />, ssr: false });
const CarExchangeOffer = loadable(() => import("./pages/desktop/car-exchange-offer"), { fallback: <PageLoader />, ssr: true });
const SFForm = loadable(() => import("./pages/desktop/sf-form"), { fallback: <PageLoader />, ssr: false });
const PriceView = loadable(() => import("./pages/desktop/price-view"), { fallback: <PageLoader />, ssr: false });
const SFLandingPage = loadable(() => import("./pages/desktop/sf-landing-page"), { fallback: <PageLoader />, ssr: true });
const RtoShare = loadable(() => import("./pages/desktop/rto-share-container"), { fallback: <PageLoader />, ssr: true });
const PLForm = loadable(() => import("./pages/desktop/pl-form"), { fallback: <PageLoader/>, ssr: true });
const SellerOffer = loadable(() => import("./pages/desktop/seller-offer"), { fallback: <PageLoader />, ssr: false });
const SellUsedLuxuryCarsContainer = loadable(() => import("./pages/desktop/sell-used-luxury-cars"), { fallback: <PageLoader />, ssr: true });
const StudioPhotos = loadable(() => import("./pages/desktop/studio-photos"), { fallback: <PageLoader />, ssr: false });
const StudioPhotosStatus = loadable(() => import("./pages/desktop/studio-photos-status"), {fallback: <PageLoader/>, ssr: false});

const Root = ({ route, children }) => {
    return (
        <React.Fragment>
            {renderRoutes(route.routes)}
            {children}
        </React.Fragment>
    );
};

Root.propTypes = {
    route: PropTypes.object,
    children: PropTypes.object
};

const routes = [
    {
        path: "/",
        component: withRouter(withDesktopConfiguration(Root)),
        init: "./init-top.desktop",
        routes: [
            {
                path: "/sell-used-cars",
                exact: true,
                name: "home",
                component: Home,
                init: "./home.desktop"
            },
            {
                path: "/sell-used-luxury-cars",
                exact: true,
                name: "sell-used-luxury-cars",
                component: SellUsedLuxuryCarsContainer,
                init: "./luxury-car.desktop"
            }, {
                path: "/seller-offer/:token",
                exact: true,
                name: "seller-offer",
                component: SellerOffer
            },
            {
                path: "/book-app",
                exact: true,
                name: "bookapp-new",
                component: DSA,
                init: "./dsa.desktop"
            },
            {
                path: "/dsa-evaluation",
                exact: true,
                name: "dsa-evaluation",
                component: DsaStepContainer,
                init: "./dsa-evaluation.desktop"
            },

            {
                path: "/car-evaluation",
                exact: true,
                name: "car-evaluation",
                component: CarEvaluation,
                init: "./car-evaluation.desktop"
            },
            {
                path: "/sell-online-evaluation",
                exact: true,
                name: "sell-online-evaluation",
                component: evaluation,
                init: "./sell-online-questions.desktop"
            },
            {
                path: "/appointment/booking",
                exact: true,
                name: "appointment-booking",
                component: SellOnlineCarEvaluation,
                init: "./sell-online-offer-price.desktop"
            },
            {
                path: "/security/terms-and-condition",
                exact: true,
                name: "terms-and-condition",
                component: TermsAndCondition,
                init: "./home.desktop"
            },
            {
                path: "/security/nda",
                exact: true,
                name: "nda",
                component: Nda,
                init: "./home.desktop"
            },
            {
                path: "/security/hall-of-fame",
                exact: true,
                name: "hall-of-fame",
                component: HallOfFame,
                init: "./home.desktop"
            },
            {
                path: "/sell-marketing",
                exact: true,
                name: "sell-marketing",
                component: SellMarketing,
                init: "./home.desktop"
            },
            {
                path: "/car-exchange-offer",
                exact: true,
                name: "car-exchange-offer",
                component: CarExchangeOffer,
                init: "./car-exchange-offer.desktop"
            },
            {
                path: "/tu-cibil-terms",
                exact: true,
                name: "tu-cibil-terms",
                component: TuCibilTerms
            },
            {
                path: "/offer/:slug",
                exact: true,
                name: "marketing",
                component: AllianceOffer,
                init: "./alliance-offer.desktop"
            },
            {
                path: "/documents",
                exact: true,
                name: "documents",
                component: Documents
            },
            {
                path: "/bank-statement-upload",
                exact: true,
                name: "bank-statement-upload",
                component: BankStatement,
                init: "./bank-statement.desktop"
            },
            {
                path: "/order-payment/:token",
                exact: true,
                component: OrderPayment
            },
            {
                path: "/referral",
                exact: true,
                name: "refer",
                component: Refer,
                init: "./refer.desktop"
            },
            {
                path: "/refer",
                exact: true,
                name: "refer",
                component: Refer,
                init: "./refer.desktop"
            },
            {
                path: "/refer/share",
                exact: true,
                name: "refer-share",
                component: Refer,
                init: "./refer.desktop"
            },
            {
                path: "/franchise",
                exact: true,
                name: "franchise",
                component: Franchise,
                init: "./franchise.desktop"
            },
            {
                path: "/franchise-form",
                exact: true,
                name: "franchiseForm",
                component: FranchiseForm
            },
            {
                path: "/unsubscribe/:token",
                exact: true,
                isUsedCarRoute: true,
                name: "unsubscribe",
                component: Unsubscribe
            },
            {
                path: "/need-help",
                exact: true,
                name: "need-help",
                component: NeedHelp,
                init: "./need-help.desktop"
            },
            {
                path: "/:feedback(resolution-feedback)",
                exact: true,
                name: "need-help-feedback",
                component: NeedHelp,
                init: "./need-help.desktop"
            },
            {
                path: "/mobile-apps",
                exact: true,
                name: "mobile-apps",
                component: AppLanding
            },
            {
                path: "/app-download",
                exact: true,
                name: "app-download",
                component: AppDownload,
                init: "./app-download"
            },
            {
                path: "/buy-used-cars/user-consent/v1/terms-and-conditions",
                exact: true,
                name: "user-consent-terms",
                component: UserTermsAndConditions
            },
            {
                path: "/token/terms-and-conditions",
                exact: true,
                name: "terms-and-conditions",
                component: TokenTermsCondition
            },
            {
                path: "/appointment/index",
                exact: true,
                name: "appointment",
                component: Appointment,
                init: "./appointment.desktop"
            },
            {
                path: "/price-view/:token",
                exact: true,
                name: "price-view",
                component: PriceView,
                init: "./appointment.desktop"
            },
            {
                path: "/appointment/confirmation",
                exact: true,
                name: "appointment-confirmation",
                component: Confirmation,
                init: "./appointment-confirmation.desktop"
            },
            {
                path: "/customer-review/submit/:appointmentID",
                exact: true,
                name: "customer-review-submission",
                component: ReviewSubmission,
                init: "./review-submission.desktop"
            },
            {
                path: "/customer-review/:appointmentID",
                exact: true,
                name: "customer-review",
                component: Experience,
                init: "./experience.desktop"
            },
            {
                path: "/terms-of-use",
                exact: true,
                name: "terms-of-use",
                component: TermsOfUse
            },
            {
                path: "/rto-vehicle-registration-details:query?",
                exact: true,
                name: "rto",
                component: RTO,
                init: "./rto.desktop"
            },
            {
                path: "/rto-details",
                exact: true,
                name: "rto-details",
                component: RtoDetails,
                init: "./rto.desktop"
            },
            {
                path: "/vehicle-details",
                exact: true,
                name: "vehicle-details",
                component: RtoShare
            },
            {
                path: "/used-car-valuation",
                exact: true,
                name: "used-car-evaluation",
                component: UsedCarValuation,
                init: "./car-valuation.desktop"
            },

            {
                path: "/dealer-onboarding",
                exact: true,
                component: BecomeOurPartner,
                init: "./become-our-partner.desktop"
            },

            {
                path: "/buy-used-cars/feedback/:formId?",
                exact: true,
                component: BuyUsedCarFeedback,
                name: "buy-used-cars-feedback",
                isUsedCarRoute: true
            },
            {
                path: "/sell-used-cars/feedback",
                exact: true,
                component: BuyUsedCarFeedback,
                name: "sell-used-cars-feedback"
            },
            {
                path: "/become-our-partner",
                exact: true,
                component: BecomeOurPartner,
                init: "./become-our-partner.desktop"
            },
            {
                path: "/cars24-pricing",
                exact: true,
                component: Cars24Pricing,
                init: "./cars24-pricing.desktop"
            },
            {
                path: "/classified-vs-cars24",
                exact: true,
                component: ClassifiedCars24,
                init: "./classifieds-vs-cars24.desktop"
            },
            {
                path: "/cars24-protect",
                exact: true,
                name: "cars24-protect",
                component: Cars24Protect
            },
            {
                path: "/sell-online",
                name: "sell-online",
                exact: true,
                component: SellOnlineContainer,
                init: "./sell-online.desktop"
            },
            {
                path: "/why-cars24/:category?",
                exact: true,
                name: "why-cars24",
                component: whyCars24
            },
            {
                path: "/payment/checkout/:slug/:orderId",
                exact: true,
                name: "payment-checkout",
                component: Payment
            },
            {
                path: "/tokenpayment/:customerType(buyer|seller)/:dealHash",
                exact: true,
                name: "e-payment",
                component: EPayment
            },
            {
                path: "/sell-used-car-:city",
                exact: true,
                name: "sell-used-car",
                component: SellUsedCar,
                init: "./sell.used.car.desktop"
            },
            {
                path: "/traffic-challan",
                exact: true,
                name: "traffic-challan",
                component: EChallan,
                init: "./traffic.challan.desktop"
            },
            {
                path: "/pre-delivery-inspection",
                exact: true,
                name: "pre-delivery-inspection",
                component: PreDeliveryInspection,
                init: "./pre.delivery.inspection.desktop"
            },
            {
                path: "/pre-delivery-inspection/slot/:token",
                exact: true,
                name: "pre-delivery-inspection",
                component: PreDeliveryInspectionAppointment
            },
            {
                path: "/pre-delivery-inspection/confirmation/:token",
                exact: true,
                name: "pre-delivery-inspection",
                component: PreDeliveryInspectionConfirmation
            },
            {
                path: "/scrap-cars",
                exact: true,
                name: "scrap-cars",
                component: ScrapCars,
                init: "./scrap.cars.desktop"
            },
            {
                path: "/scrap-cars/info/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsInfo,
                init: "./scrap.cars.info.desktop"
            },
            {
                path: "/scrap-cars/questions/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsQuestions,
                init: "./scrap.cars.questions.desktop"
            },
            {
                path: "/scrap-cars/final-price/:token",
                exact: true,
                name: "scrap-cars",
                component: ScrapCarsFinalPrice,
                init: "./scrap.cars.final.price.desktop"
            },
            {
                path: "/:state-traffic-challan-status",
                exact: true,
                name: "traffic-challan",
                component: StateEChallan,
                init: "./state.challan.desktop"
            },
            {
                path: "/my-challans/:token?/:action?/:orderId?",
                exact: true,
                name: "traffic-challan",
                component: MyChallans,
                init: "./my.challan.desktop"
            },
            {
                path: "/car-service-history",
                exact: true,
                name: "car-service-history",
                component: CarServiceHistory,
                init: "./car-service-history.desktop"
            },
            {
                path: "/car-service-history-info/:token?",
                exact: true,
                name: "car-service-history",
                component: CarServiceHistoryInfo,
                init: "./car-service-history-info.desktop"
            },
            {
                path: "/order-summary/:orderNumber",
                exact: true,
                name: "order-summary",
                component: MyOrderSummary,
                init: "./order.summary.desktop"
            },
            {
                path: "/order-status/:orderNumber/:token?",
                exact: true,
                name: "order-status",
                component: MyOrderStatus,
                init: "./order.status.desktop"
            },
            {
                path: "/my-orders",
                exact: true,
                name: "my-orders",
                component: MyOrders
            },
            {
                path: "/seller-vas-report-download/:token",
                exact: true,
                name: "seller-vas-report-download",
                component: SellerVasReportDownload
            },
            {
                path: "/inspection-complete/:token",
                exact: true,
                name: "page-not-found",
                component: PostInspection,
                init: "./post-inspection-journey.desktop"
            },
            {
                path: "/seller-financing/:token",
                exact: true,
                name: "seller-financing",
                component: SFForm
            },
            {
                path: "/employee/settlement",
                exact: true,
                name: "employee-fnf",
                component: EmployeeFnF
            },
            {
                path: "/fourdoor",
                exact: true,
                name: "fourdoor",
                component: Fourdoor
            },
            {
                path: "/account",
                exact: true,
                name: "account-login",
                component: AccountLogin
            },
            {
                path: "/account/profile",
                exact: true,
                name: "my-profile",
                component: MyProfile
            },
            {
                path: "/account/login",
                exact: true,
                name: "account-login",
                component: AccountLogin
            },
            {
                path: "/account/appointments",
                exact: true,
                name: "user-appointments",
                component: UserAppointments
            },
            {
                path: "/car-health/:token?",
                exact: true,
                name: "car-health",
                component: CarHealth
            },
            {
                path: "/loan-against-car",
                exact: true,
                name: "sf-landing-page",
                component: SFLandingPage,
                init: "./sf-landing.desktop"
            },
            {
                path: "/personal-loan",
                exact: true,
                name: "personal-loan",
                component: PLForm,
                init: "./pl-form.desktop"
            },
            {
                path: "/studio-photos/:id",
                exact: true,
                name: "studio-photos",
                component: StudioPhotos
            },
            {
                path: "/studio-photos-status/:orderNumber",
                exact: true,
                name: "studio-photos-status",
                component: StudioPhotosStatus
            },
            {
                path: "/:slug1?/:slug2?/:slug3?/:slug4?",
                exact: true,
                name: "seo",
                component: Seo,
                init: "./seo.desktop"
            },
            {
                path: "*",
                exact: true,
                name: "not-found",
                component: NotFound
            }
        ]
    }
];

export { routes };
