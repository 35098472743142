import axios from "axios";
import { consumerFinanceApiUrl } from "../constants/url-constants";
import { getDeviceType } from "../utils/helpers/device-type";
import { getCookiesValueObjectFromKeys } from "../utils/helpers/get-cookie-values-object-from-keys";
import getDeviceOs from "../utils/helpers/get-device-os";
const { v4: uuidv4 } = require("uuid");
const UAParser = require("ua-parser-js");

const api = axios.create({
    baseURL: consumerFinanceApiUrl()
});

api.interceptors.request.use(
    (config) => {
        const cookiesKeys = ["statsigStableId", "userToken", "_ga"];
        const cookiesValues = getCookiesValueObjectFromKeys(cookiesKeys);
        const {statsigStableId, userToken, _ga: gaId } = cookiesValues || {};
        const userGaId = (gaId || "").replace(/GA[0-9]\.[0-9]\./, "");
        const  { source, type} = getDeviceType() || {};
        const parser = new UAParser();
        const desktopOs = parser.getOS().name;

        config.headers["X-Source"] = source;
        config.headers["X-Device-Type"] = type === "Desktop" ?  desktopOs : getDeviceOs();
        config.headers["X-Request-Id"] = uuidv4();
        config.headers["X-Device-Id"] = statsigStableId || userGaId;

        if (config.headers.Authorization || userToken) {
            config.headers.Authorization = config.headers.Authorization || `Bearer ${userToken}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// eslint-disable-next-line max-statements
export default () => {

    const fetchFinanceDetail = (vehiclePrice) => {
        return api.get(`/app/offer/emi-calculation?vehiclePrice=${vehiclePrice}`, {
            headers: {
                isEMITenureVariant: true
            }
        });
    };

    const fetchFinanceDetailAE = (vehiclePrice) => {
        return api.get(`/app/offer/emi-calculation?vehiclePrice=${vehiclePrice}`, {
            headers: {
                X_COUNTRY: "AE",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const uploadDocument = (params) => {
        return api.post(`/app/document`, params);
    };

    const uploadDocumentV2 = (params) => {
        return api.post(`/app/document/v2`, params);
    };

    const validatePan = ({shouldValidateDobAndName = false, ...params}) => {
        return api.post(`/app/validate/pan?${shouldValidateDobAndName ? `shouldValidateDobAndName=${shouldValidateDobAndName}` : ""}`, params);
    };

    const getBankList = () => {
        return api.get("/app/bank-list");
    };

    const getBankStatementStatus = (userId) => {
        return api.get(`/app/bank-statement-upload-status/${userId}`);
    };

    const generateOffer = (params) => {
        return api.post("app/offer/generate", params);
    };

    const submitFinanceAnswers = (data) => {
        return api.post(`/app/offer/pre-approved`, data);
    };

    const generateCfOffer = ({type = "CHECKOUT", ...payload}) => {
        return api.post(`app/user/finance/offer?type=${type}`, payload);
    };

    const submitUserCfDetails = (data) => {
        return api.post(`/app/user/details`, data);
    };

    const submitUserCfDetailsV2 = (data) => {
        return api.post(`/app/v2/user/details`, data);
    };

    const submitUserFormDetails = (data) => {
        return api.post(`/app/v3/user/details`, data);
    };

    const checkCfOffer = (userId, mobileNumber) => {
        return api.get(`/app/user-check/${userId}`, { params: { mobileNumber } });
    };

    const validateDocumentUpload = (userId, uid, params) => {
        return api.get(`/app/document/status/${userId}/${uid}`, {
            params
        });
    };

    const getLoanStages = (userId) => {
        return api.get(`/app/loan-status/${userId}`);
    };

    const getCreditVerificationFormStaticData = () => {
        return api.get(`/app/interest-form/static`);
    };

    const getCreditVerificationForm = (userId) => {
        return api.get(`/app/interest-form/${userId}`);
    };

    const postCreditVerificationForm = (userId, data) => {
        return api.post(`/app/interest-form/${userId}`, data);
    };

    const postCreditVerificationFormSelfEmployed = (userId, data) => {
        return api.post(`/app/interest-form/self-employed/${userId}`, data);
    };

    const updateUserData = (params) => {
        return api.put(`/app/user-data/`, params);
    };

    const updateUserDataForSnackBar = (userId, params) => {
        return api.patch(`/app/user/${userId}/user-data`, params);
    };

    const getFeedbackFormData = (type) => {
        return api.get(`/app/user/feedback?type=${type}`);
    };

    const postFeedbackFormData = (userId, payload) => {
        return api.post(`/app/user/feedback/${userId}`, payload);
    };

    const fetchLoanDataForDocumentVerification = (userId, token) => {
        return api.get(`/app/tnc/${userId}`, {
            headers: {
                Authorization: token
            }
        });
    };

    const getFinanceFormData = (userId) => {
        return api.get(`/app/offer-form-data/${userId}`);
    };

    const getLoanTnC = (userId, token) => {
        return api.get(`/app/tnc/${userId}`, {
            headers: {
                Authorization: token
            }
        });
    };

    const formOneInitiate = (payload) => {
        return api.post(`/app/user`, payload);
    };

    const getFinvuData = ({userId, mobile, token, source, startAnew} = {}) => {
        return api.put(`/app/banking?userId=${userId}&mobileNumber=${mobile}&bankingBy=${source}&startAnew=${startAnew}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getBankAnalysis = (userId, mobileNumber, token) => {
        return api.get(`/app/banking/analysis?userId=${userId}&mobileNumber=${mobileNumber}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const startAnalyserProcess = (userId, token, source) => {
        return api.post(`/app/banking/analysis/process?userId=${userId}&bankingBy=${source}`, null, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getProcessStatus = (trackerId, token) => {
        return api.get(`/app/banking/analysis/status?trackerId=${trackerId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                X_COUNTRY: "IN",
                X_VEHICLE_TYPE: "car"
            }
        });
    };

    const getCalulatorOffers = (params) => {
        return api.post("app/offer/generate/without/banking", params);
    };

    const getUserSelectedOffer = (payload) => {
        return api.post("/app/offer/post-checkout/selected", payload);

    };

    const getUserSelectedOfferV2 = (payload) => {
        return api.post("/app/v2/offer/post-booking", payload);
    };

    const saveUserSelectedOfferV2 = (payload) => {
        return api.put("/app/offer/post-booking", payload);
    };

    const saveUserSelectedOffer = (payload) => {
        return api.post("app/offer/post-checkout/save", payload);
    };

    const getCfBankList = (type = "") => {
        return api.get(`/app/banks${type ? `?banksType=${type}` : ""}`);
    };

    const getCkycDetails = ({uuid, userId, applicantType}) => {
        return api.get(`/ckyc/v2/${userId}`, {
            params: { uuid, applicantType }
        });
    };

    const updateCkycDetails = ({uuid, userId, applicantType, payload}) => {
        return api.post(`/ckyc/v2/${userId}?applicantType=${applicantType}&uuid=${uuid}`, payload);
    };

    const getOfferRanges = (orderId) => {
        return api.get("/app/offer/range", { params: { orderId }});
    };

    const saveUserPersonalData = (payload) => {
        return api.post("app/user/details/pre-fill?type=equifax", payload);
    };

    const getCfExperimentVariants = (userId) => {
        return api.get(`app/user/${userId}/variants`);
    };

    const saveCibilConsent = ({userId, secureToken}) => {
        return api.post(`app/consent/user/${userId}`, null, {
            headers: {
                Authorization: `Bearer ${secureToken}`
            }
        });
    };

    const getCibilReportUrl = (userId) => {
        return api.get(`/cibil/soft-pull/report/user/${userId}`);
    };

    const getRtdAuthToken = (userId) => {
        return api.get("app/firebase/token", {
            params: {
                userId
            }
        });
    };

    const initiatePhysicalKyc = ({userId, applicantType, uuid}) => {
        return api.post(`app/user/${userId}/kyc/physical/start?applicantType=${applicantType}&uuid=${uuid}`, null);
    };

    const generateKycOTP = ({userId, uuid, applicantType, payload}) => {
        return api.post(`app/user/${userId}/okyc/generate-otp?applicantType=${applicantType}&uuid=${uuid}`, payload);
    };

    const submitOkycDetails = ({userId, uuid, applicantType, payload}) => {
        return api.post(`app/user/${userId}/okyc/download-aadhaar-data?applicantType=${applicantType}&uuid=${uuid}`, payload);
    };

    return {
        fetchFinanceDetail,
        fetchFinanceDetailAE,
        uploadDocument,
        uploadDocumentV2,
        validatePan,
        getBankList,
        generateOffer,
        checkCfOffer,
        submitFinanceAnswers,
        getBankStatementStatus,
        validateDocumentUpload,
        updateUserData,
        fetchLoanDataForDocumentVerification,
        getLoanStages,
        getCreditVerificationFormStaticData,
        getCreditVerificationForm,
        postCreditVerificationForm,
        postCreditVerificationFormSelfEmployed,
        getFeedbackFormData,
        postFeedbackFormData,
        getFinanceFormData,
        getLoanTnC,
        formOneInitiate,
        getCalulatorOffers,
        getFinvuData,
        getBankAnalysis,
        startAnalyserProcess,
        getProcessStatus,
        submitUserCfDetails,
        generateCfOffer,
        getUserSelectedOffer,
        saveUserSelectedOffer,
        getCfBankList,
        getCkycDetails,
        updateCkycDetails,
        getOfferRanges,
        getUserSelectedOfferV2,
        saveUserSelectedOfferV2,
        saveUserPersonalData,
        getCfExperimentVariants,
        submitUserCfDetailsV2,
        saveCibilConsent,
        getCibilReportUrl,
        getRtdAuthToken,
        initiatePhysicalKyc,
        generateKycOTP,
        submitOkycDetails,
        updateUserDataForSnackBar,
        submitUserFormDetails
    };
};
