export const getCMSSSRPayload = [
    "make_list",
    "seo_content",
    "city_list",
    "customer_review_list",
    "tweets"
];
export const getMobileCMSSSRPayload = [
    "make_list",
    "seo_content",
    "city_list",
    "customer_review_list"
    // "tweets"
];
//
// export const API_PATH_ALTERNATE = {
//     SEO_CONTENT: "/cms/get-content",
//     MAKE: "/make",
//     MODEL: "/make",
//     YEAR: "/get-year-list",
//     KILOMETER: "/lead/get-km-list",
//     VARIANT: "/model/fuel-variants",
//     FOOTER_LINKS: "/site/footer-links-list",
//     CITY: "/city/view",
//     STATE: "/lead/get-states",
//     RECENTLY_INSPECTED: "/get-recently-inspected",
//     TESTIMONIAL: "/testimonials-list",
//     CRITIC_REVIEWS: "/critic-reviews",
//     MEDIA_LOVE: "/site/media-love",
//     BLOG: "/cms/blog-posts",
//     LEAD_CREATE: "/create-lead",
//     LEAD_UPDATE: "/update-lead",
//     GEO_SLOT: "/get-geo-store-slot",
//     BRANCH_BY_CITY: "/location/locations-by-city", //check
//     APPOINTMENT: "/geo-index-appointment",
//     APPOINTMENT_CONFIRMATION: "/confirmation-appointment",
//     APPOINTMENT_RESCHEDULE: "/reschedule-appointment",
//     OTP_SEND: "/send-otp",
//     OTP_VERIFY: "/otp-verify"
// };

export const API_PATH_ALTERNATE = {
    SEO_CONTENT: "/api/v1/content",
    MAKE: "/make",
    MODEL: "/make",
    MODEL_BASED_ON_YEAR_AND_MAKE: "/model/",
    YEAR: "/get-year-list",
    YEAR_BASED_ON_MAKE: "/years",
    KILOMETER: "/get-km-list",
    VARIANT: "/variant-fuel-list",
    // FOOTER_LINKS: "/footer-links-list",
    //FOOTER_LINKS: "/get-footer-links",
    //MOBILE_FOOTER_LINKS: "/get-footer-links",
    CITY: "/city",
    STATE: "/get-states",
    RECENTLY_INSPECTED: "/get-recently-inspected",
    TESTIMONIAL: "/testimonials",
    CRITIC_REVIEWS: "/critic-reviews",
    MEDIA_LOVE: "/media-love-us",
    BLOG: "/cms/blog-posts",
    LEAD_CREATE: "/create-lead",
    LEAD_UPDATE: "/update-lead",
    LEAD_DETAILS: "/lead-details",
    BRANCH_BY_CITY: "/location/locations-by-city", //check
    APPOINTMENT: "/geo-index-appointment",
    APPOINTMENT_CONFIRMATION: "/appointment/confirmation",
    APPOINTMENT_RESCHEDULE: "/appointment/reschedule",
    OTP_SEND: "/send-otp",
    LEAD_OTP_SEND: "/lead-send-otp",
    OTP_VERIFY: "/otp-verify",
    LEAD_OTP_VERIFY: "/lead-otp-verify",
    SAVE_USER_EMAIL: "/save-user-email",
    APPOINTMENT_DETAILS: "/appointment-details",
    GET_VERIFIED: "/api/sms/get-verified/",
    BANNER_WIDGET: "/appointment-widget",
    RE_VERIFY_APPOINTMENT: "/verify-auth-user-appointment",
    FAQ_LIST: "/faq-search",
    CREATE_CUSTOMER_REVIEW: "/create-customer-review",
    CUSTOMER_REVIEW: "/customer-review",
    UPDATE_CUSTOMER_REVIEW: "/update-customer-review",
    UPLOAD_CUSTOMER_IMAGE: "/upload-customer-image",
    CREATE_ONE_STEP_APPOINTMENT: "/create-one-step-geo-appointment",
    UPDATE_ONE_STEP_APPOINTMENT: "/update-one-step-geo-appointment",
    GET_BRANCH_CITY_COUNT: "/get-branch-and-city-counts",
    REFER_USER: "/refer-user",
    SUBMIT_REQUEST_DOWNLOAD_LINK: "/send-app-downloadlink",
    RC_SUMMARY: "/rc-summary",
    USER_APPOINTMENT_DETAILS: "/get-user-appointments",
    CONCERN_LIST: "/get-concern-list",
    CREATE_USER_CONCERN: "/raise-concern",
    CUSTOMER_REVIEW_LIST: "/customer-review/get-reviews",
    RESOLUTION_FEEDBACK: "/resolution-feedback",
    RTO_PAGE: "/rto-page",
    RTO: "/rto",
    CONFIG: "/get-config",
    SEO: "/seo",
    ACKO_LEAD: "/acko-coupon-opt-in",
    RTO_LIST: "/get-rto-list",
    CONTENT_HOOK: "/city-banner-list",
    PINCODES: "/get-pincodes",
    SUBMIT_FRANCHISE: "/save-franchise",
    FUEL_PRICE: "/fuel-price/get-fuel-price",
    REFERRAL_CODE: "get-referral-code",
    SUBMIT_LOAN: "/car-loan/save-loan",
    SUBMIT_ONBOARDING: "/onboarding-save",
    CAR_LOAN_SEND_OTP: "/car-loan/phone-verify",
    FETCH_FINANCE_POLICIES: "/get-finance-pdf",
    SAVE_CLASSIFIED_DETAILS: "/save-classified-details",
    UPLOAD_CLASSIFIED_IMAGE: "/upload-classified-image",
    CREATE_ORDER: "/order/create",
    CHATBOT_FAQ: "/get-chatbot-faq",
    SAVE_RC_INSURANCE_COPY: "/save-rc-and-insurance-copy",
    UPLOAD_VEHICLE_FILE: "/upload-vehicle-file",
    FETCH_ALLIANCE_DETAILS: "/get-alliance-detail",
    ACKO_EXCHANGE_CAR_DETAIL: "/api/acko/car/detail",
    SAVE_ACKO_DETAILS: "/save-acko-details",
    FETCH_SERVICE_CATEGORIES: "/category",
    FETCH_SERVICE_LIST: "/services",
    CREATE_CAR_SERVICE_LEAD: "/lead",
    UPDATE_CAR_SERVICE_LEAD: "/lead",
    CAR_SERVICE_SEND_OTP: "/send-otp",
    CAR_SERVICE_VERIFY_OTP: "/lead/otp-verify",
    GET_CAR_SERVICEABLE_CITY: "/get-geo-service-city",
    GET_CAR_SERVICE_LEAD_DETAILS: "/lead",
    CAR_SERVICES: "/services",
    CREATE_CAR_SERVICE_ORDER: "/order",
    UPDATE_CAR_SERVICE_ORDER: "/order",
    GET_CAR_SERVICE_VALID_CITY: "/get-car-service-city",
    GEO_DETAILS: "/api/v1/geo-details",
    GEO_SEARCH_DETAILS: "search-location",
    REQUEST_CALLBACK: "requested-callback",
    B2C_REQUEST_CALLBACK: "/b2c-request-callback",
    C24Q_CAR_PRICE: "/c24-quote-all-content",
    C24QUOTE_PRICE_INPUT: "/customer-quote-price-input",
    SAVE_LEAD_IMAGES: "/save-lead-images",
    DELETE_UPLOADED_IMAGES: "/update-vehicle-file",
    GET_SELL_ONLINE_OFFER_PRICE: "/sell-online-offer-all-content",
    POST_SELL_ONLINE_OFFER_INPUT: "/sell-online-offer-input",
    SELL_ONLINE_PRICE_FEEDBACK: "/sell-online-price-feedback",
    SELL_ONLINE_CALLBACK_LEAD: "/sell-online-offer/callback-lead",
    SELL_ONLINE_QUESTION: "/sell-online-question",
    VEHICLE_NUMBER: "/vehicle-number",
    LEAD_WITHOUT_PHONE: "/api/v1/ab/lead",
    CJ_DETAILS: "/exophone",
    VIRTUAL_NUMBER: "/api/v1/appointment/token",
    DSA_DETAIL: "/dsa",
    DSA_TWO_STEP_APPOINTMENT: "/appointment",
    BUYER_TESTIMONIAL: "/b2c/testimonials",
    APPOINTMENT_DETAILS_BY_TOKEN: "/appointment/token",
    APPOINTMENT_DETAILS_BY_ID: "/appointment",
    CANCEL_APPOINTMENT: "/appointment/cancel",
    DIY_APPOINTMENT: "/api/v1/appointment/token",
    CONSUMER_SERVICE_SEND_OTP: "/send/otp",
    CONSUMER_SERVICE_VERIFY_OTP: "/otp/verify",
    CANCEL_DIY_APPOINTMENT: "/ri/cancel",
    DIY_UPLOAD_DOCUMENTS: "/document/upload",
    PURCHASE_REQUEST: "/purchase-request",
    DIY_BANK_VERIFY: "/bank/verify",
    GS_DENY: "/gs/deny",
    REQUEST_CALLBACK_APPOINTMENT: "request-callback",
    GET_QUESTION_FOR_USER_INTENT: "/appointment/questionnaire",
    WHATSAPP_CONFIRMATION: "/customer/available",
    SELLER_REVIEWS: "/seller/reviews",
    DUPLICATE_APPOINTMENT: "/appointment/check",
    VERIFY_AUTH_USER_LEAD: "/verify-auth-user-lead",
    MY_APPOINTMENTS: "/user-appointments",
    SIMILAR_CARS_SOLD: "/similar-cars",
    IFSC_DETAILS: "/api/v1/ifsc",
    UPDATE_LEAD_DETAIL: "/update-lead-detail",
    PRE_INSPECTION_DOC_UPLOAD: "/pre-inspection",
    POPULAR_CITIES: "/state/city-list",
    RTO_LEAD: "/rto/lead",
    GEO_STORE_SLOT: "/api/v2/slots/available/store-and-zone",
    GET_CONSULTATION_SLOTS: "/api/v1/car-expert/consultation/slots",
    SCHEDULE_CONSULTATION: "/schedule-expert-call",
    RTO_DETAIL_BY_REG_NO: "/v1/rto-details",
    DUPLICATE_INSPECTED_APPOINTMENT: "/appointment/inspected",
    PROSPECT_LEAD: "/prospect/lead",
    CHALLAN_LIST: "/challan/list",
    CART: "/api/v1/cart",
    ORDER: "/api/v1/order",
    SCRAP_CAR_QUESTIONS: "/scrap-car/questionnaire",
    CAR_SERVICE_HISTORY: "/service-history",
    REPEAT_INSPECTION: "/repeat-inspection-by-token",
    SAVE_SCRAP_CAR_QUESTION: "/scrap-car/question/reply",
    SCRAP_CAR: "/scrap-car",
    CC_DROP_LEAD: "/cc-lms-drop-off-lead",
    CHECK_GA_EXPERIMENT: "/config/check/ga-experiment",
    SELF_NEGO_PRICE: "/self-nego-price",
    RESTART_SELF_NEGO_AUCTION: "/self-nego/request-price",
    SAVE_DNP_DECISION: "/self-nego/dnp-decision",
    PRODUCT_CAR_WASH: "/api/v1/product/car-wash",
    CAR_WASH_SLOTS: "/api/v1/carwash/slots",
    BOOK_CAR_WASH: "/api/v1/carwash/book",
    CANCEL_CAR_WASH: "/api/v1/carwash/cancel",
    RESTART_AUCTION: "/repeat-user",
    CHANNEL_PARTNER_LEAD: "/channel-partner",
    MARK_DOC_NOT_UPLOADD: "/document/not/uploaded",
    GALLERY_IMAGES: "/gallery/images",
    SELF_NEGO: "/self-nego",
    PRODUCT_CAR_HEALTH: "/api/v1/car-health-report/product",
    CHR_LEAD_DETAILS: "/chr/lead-details",
    CAR_HEALTH_CREATE_ORDER: "api/v1/car-health-report/create-order",
    CAR_SERVICE_HISTORY_PRICE: "/api/v1/product/service-history",
    GALLERY_VIDEO: "/gallery/video",
    INSPECTION_LEAD: "/inspection-lead",
    PRE_DELIVERY_INSPECTION_ORDER: "/api/v1/pre-delivery-inspection/create-order",
    PRE_DELIVERY_INSPECTION_PRICE: "/api/v1/pre-delivery-inspection/product",
    PRE_DELIVERY_INSPECTION_PRICE_ALL: "/api/v1/pre-delivery-inspection/product/all",
    PRE_DELIVERY_INSPECTION_CMS: "/api/v1/consumer-api/page-template/pdi-page-v2",
    CITY_SEARCH: "/city/search",
    FRANCHISE: "/franchise",
    REPORT_DETAILS: "/report/details",
    GET_PRE_INS_DOC: "/api/v1/pre-inspection/token",
    CAMPAIGN_TOKEN: "/campaign/token",
    LEAD_ATTRIBUTES: "lead-attributes",
    START_DIRECT_OCB_AUCTION: "/direct-ocb/request-price",
    START_GS_OCB_AUCTION: "/gs-ocb/request-price",
    STATE_CHALLAN_OTP: "/challan/state-challans/otp",
    CONSUMER_BASE_PATH_V1: "/api/v1/consumer-api",
    PAGE_TEMPLATE: "/page-template",
    SCRAP_CAR_PAGE: "/scrap-car-page",
    BANNER_WIDGET_V2: "/bannerwidgetv2/list",
    TRACK_CHR_REFERRAL: "/api/v1/page/visited",
    GET_INVOICE: "/document/details/invoice",
    GET_KM_RANGE: "get-km-range-list",
    GET_STUDIO_PHOTOS: "/api/v1/photoshoot-images/fulfillment-details",
    CHECK_PDI_ELIGIBILITY: "product/eligibility/pre-delivery-inspection"
};

export const API_PATH = {
    SEO_CONTENT: "/api/v1/content",
    MAKE: "/make",
    MODEL: "/make",
    YEAR: "/get-year-list",
    KILOMETER: "/get-km-list",
    VARIANT: "/fuel-variant",
    FOOTER_LINKS: "/site/footer-links-list",
    MOBILE_FOOTER_LINKS: "/get-footer-links",
    CITY: "/city",
    STATE: "/get-states",
    RECENTLY_INSPECTED: "/get-recently-inspected",
    TESTIMONIAL: "/testimonials",
    CRITIC_REVIEWS: "/critic-reviews",
    MEDIA_LOVE: "/site/media-love",
    BLOG: "/cms/blog-posts",
    LEAD_CREATE: "/lead/create",
    LEAD_UPDATE: "/lead/update",
    LEAD_DETAILS: "/lead",
    GEO_SLOT: "/get-geo-store-slot",
    BRANCH_BY_CITY: "/location/locations-by-city",
    APPOINTMENT: "/appointment/geo-index/",
    APPOINTMENT_CONFIRMATION: "/appointment/confirmation",
    APPOINTMENT_RESCHEDULE: "/appointment/reschedule",
    OTP_SEND: "/appointment/send-otp",
    OTP_VERIFY: "/appointment/otp-verify",
    FAQ_LIST: "/faq-search",
    CREATE_CUSTOMER_REVIEW: "/create-customer-review",
    CUSTOMER_REVIEW: "/customer-review",
    UPDATE_CUSTOMER_REVIEW: "/update-customer-review",
    UPLOAD_CUSTOMER_IMAGE: "/upload-customer-image",
    REFER_USER: "/refer-user",
    SUBMIT_REQUEST_DOWNLOAD_LINK: "/send-app-downloadlink",
    RC_SUMMARY: "/rc-summary",
    USER_APPOINTMENT_DETAILS: "/get-user-appointments",
    CONCERN_LIST: "/get-concern-list",
    CREATE_USER_CONCERN: "/raise-concern",
    CUSTOMER_REVIEW_LIST: "/customer-review/get-reviews",
    RESOLUTION_FEEDBACK: "/resolution-feedback",
    RTO_PAGE: "/rto-page",
    RTO: "/rto",
    CONFIG: "/get-config",
    SEO: "/seo",
    ACKO_LEAD: "/acko-coupon-opt-in",
    RTO_LIST: "/get-rto-list",
    CONTENT_HOOK: "/city-banner-list",
    PINCODES: "/get-pincodes",
    SUBMIT_FRANCHISE: "/save-franchise",
    FUEL_PRICE: "/fuel-price/get-fuel-price",
    REFERRAL_CODE: "get-referral-code",
    SUBMIT_LOAN: "/car-loan/save-loan",
    SUBMIT_ONBOARDING: "/onboarding-save",
    CAR_LOAN_SEND_OTP: "/car-loan/phone-verify",
    FETCH_FINANCE_POLICIES: "/get-finance-pdf",
    SAVE_CLASSIFIED_DETAILS: "/save-classified-details",
    UPLOAD_CLASSIFIED_IMAGE: "/upload-classified-image",
    CREATE_ORDER: "/order/create",
    CHATBOT_FAQ: "/get-chatbot-faq",
    SAVE_RC_INSURANCE_COPY: "/save-rc-and-insurance-copy",
    UPLOAD_VEHICLE_FILE: "/upload-vehicle-file",
    FETCH_ALLIANCE_DETAILS: "/get-alliance-detail",
    ACKO_EXCHANGE_CAR_DETAIL: "/api/acko/car/detail"
};

export const LMS_API_PATH = {
    DEAL_PAYMENT_INFO: "/sell24/deal",
    REQUEST_CALLBACK_LEAD: "/webhook/lead"
};

export const C2C_SECURE_API_PATH = {
    LOAN_DETAILS: "/b2c/apply-for-loan/mobile"
};

export const COUNTRY_CODE = {
    INDIA: "IN",
    UAE: "AE"
};

export const BOOKING_PRICE = {
    AE: 499
};

export const C2B_SELLER_FINANCE_PATH = {
    CREATE_SELLER_INTENT: "/seller-intent/create",
    SUBMIT_SELLER_FORM: "/seller-intent/submit",
    UPLOAD_DOCUMENT: "/document/uploadfile",
    VERIFY_OTP: "/seller-intent/verify/otp",
    NEW_CREATE_SELLER_INTENT: "/seller-intent/capture/intent",
    INTENT_INFO: "/seller-intent/intent/info"
};
